import React,{useState} from 'react'
import '../../styles/layout.css'
import { Switch, Route, Link } from "react-router-dom";
import Sidebar from '../partials/Sidebar';
import '../../styles/layout.css'
import { FaEnvelope, FaBell, FaUser, FaUnlockAlt } from 'react-icons/fa';
import { FiMenu, FiChevronLeft } from 'react-icons/fi';
import Logout from '../Logout';

//pages
import Dashboard from '../../pages/Dashboard/Dashboard';
import Profile from '../../pages/Dashboard/Users/profile/profile';
import ChangePassword from '../../pages/Dashboard/Users/password/ChangePassword';
const Layout = () => {
    const [toggleSidebar, setToggleSidebar] = useState(false)
    return (
        <div className='layoutWrapper'>
           <Sidebar toggleSidebar={toggleSidebar}/>
            <div className={`layoutMain ${!toggleSidebar ? 'ml-256' : ''}`}>
                <header className='topHeader'>
                    <div className={`${toggleSidebar ? 'sidebarBtn ml-15' : 'sidebarBtn'}`}>
                        <button  onClick={()=>setToggleSidebar(!toggleSidebar)}>
                          {
                            toggleSidebar ? <FiMenu/> : <FiChevronLeft/>
                          } 
                        </button>
                    </div>
                    <ul className='topHeaderMenu'>
                        <li>
                            <Link to="#" className='ml-15'><FaBell /></Link>
                        </li>
                        <li>
                            <Link to="#"><FaEnvelope /></Link>
                        </li>

                        <li className="profile-menu">
                            <div className="c-avatar"><img className="c-avatar-img" src={'/images/avatar.jfif'} alt="user@email.com" /></div>
                            <ul className="profile-link-dropdown">
                                <li> <Link to="/app/profile"><FaUser/> Profile</Link></li>
                                <li> <Link to="/app/update-password"><FaUnlockAlt/> Update Password</Link></li>
                                <li> <Logout/></li>
                            </ul>
                        </li>

                    </ul>
                </header>
                <main className='appMain'>
                    <Switch>
                        <Route path="/app/main" exact component={Dashboard} />
                        <Route path="/app/profile" exact component={Profile} />
                        <Route path="/app/update-password" exact component={ChangePassword} />
                    </Switch>
                </main>
                <footer>
                    <p>Triplast ecommerce © 2022. All Rights Reserved</p>
                </footer>
            </div>

        </div>
    )
}

export default Layout