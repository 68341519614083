import React, { useState } from 'react'
import Breadcrumb from '../../../../components/partials/Breadcrumb'
import { Card } from 'react-bootstrap'
import { GetLogin, Alerts } from '../../../../helpers'
import { Formik } from 'formik';
import { FiKey } from 'react-icons/fi';
const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [isButtonDisabled, setButtonDisabled] = useState(true)
    const [isUpdate, setUpdate] = useState(false)

    const handleChangeOldPassword = (e) => {
        if (e.target.value === '') {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
        setCurrentPassword(e.target.value)
    }
    const checkPassword = async () => {
        const data = { password: currentPassword }
        let resp = await GetLogin.checkCurrentPassword(data)
        if (resp) {
            if (resp.status === true) {
                setUpdate(true)
                setButtonDisabled(true);
            } else {
                Alerts.alertMessage(resp.message, 'top-center', 'error')
                setUpdate(false)
            }
        }
    }
    const handleFormSubmit = async (values) => {
        const data = {
            new_password:values.password
        }
        let resp = await GetLogin.updatePassword(data);
        if(resp){
            if(resp.status){
                Alerts.alertMessage(resp.message, 'top-center', 'success');
                setTimeout(function () {
                    window.location.reload(); 
                }, 2000); 
            }else{
                Alerts.alertMessage(resp.message, 'top-center', 'error');
            }
        }
    }
    const breadcrumbs = [
        {
            id: 1,
            label: 'Dashboard',
            link: '/'
        },
        {
            id: 2,
            label: 'Update Password',
            link: '/'
        }
    ]

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <div className='innerPageWrapper mxw-600'>
                <Card>
                    <Card.Header><h5><FiKey/> Update Password</h5></Card.Header>
                    <Card.Body>
                        <div className='checkPasswordForm mxw-400'>
                            <input
                                type="text"
                                className='form-control'
                                name="old_password"
                                placeholder='Enter your current password'
                                value={currentPassword}
                                onChange={handleChangeOldPassword}
                                readOnly={isUpdate}
                                disabled={isUpdate}
                            />
                            <button className={isButtonDisabled ? 'btnDisabled' : ''} onClick={checkPassword} disabled={isButtonDisabled}>Go</button>
                        </div>
                        {
                            isUpdate ? <div className='updatePasswordForm mxw-400'>
                                <Formik
                                    initialValues={{
                                        password: '',
                                        confirm_password: ''
                                    }}
                                    enableReinitialize={true}
                                    validate={values => {
                                        const errors = {};

                                        if (!values.password) {
                                            errors.password = 'Password is required.';
                                        }
                                        if (!values.confirm_password) {
                                            errors.confirm_password = 'Confirm password is required.';
                                        }
                                        if(values.confirm_password !== ''){
                                            if(values.password !== values.confirm_password){
                                                errors.confirm_password = 'Confirm password does not match.';
                                            }
                                        }
                                       
                                        return errors;
                                    }}
                                    onSubmit={handleFormSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className='mb-3'>
                                                <label>Password</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                />
                                                 {errors.password && touched.password && <span className='helpError'>{errors.password}</span>}
                                            </div>
                                            <div className='mb-3'>
                                                <label>Confirm Password</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="confirm_password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.confirm_password}
                                                />
                                                 {errors.confirm_password && touched.confirm_password && <span className='helpError'>{errors.confirm_password}</span>}
                                            </div>
                                            <button type="submit" className='cPrimaryButton'>Update Now</button>
                                        </form>)}
                                </Formik>
                            </div> : null
                        }

                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default ChangePassword