import React from 'react'
import { Link } from 'react-router-dom'

import { AiOutlineDashboard } from 'react-icons/ai';
const Breadcrumb = ({breadcrumbs}) => {
  return (
    <div className='breadcrumbs'>
        <ul>
          {
            breadcrumbs && breadcrumbs.map((item, index)=>{
              return  <li key={item.id}><Link to="/">{ index === 0 ? <AiOutlineDashboard/> : ''} {item.label}</Link></li>
            })
          }
           
            
        </ul>
    </div>
  )
}

export default Breadcrumb