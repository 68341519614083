import React from 'react'
import { FiChevronLeft } from 'react-icons/fi';
import Card from 'react-bootstrap/Card';
import { Formik } from 'formik';
import { FiUser } from 'react-icons/fi'
const EditProfile = ({ goBack, data, onEditSubmit }) => {
    const handleFormSubmit = (data) =>{
        onEditSubmit(data);
    }
    return (
        <>
            <div className='profileWrapper'>
                <Card>
                    <Card.Header> <button onClick={goBack}><FiChevronLeft /></button> <h5><FiUser/> Profile Edit</h5></Card.Header>
                    <Card.Body>
                        <Formik
                            
                            initialValues={{ 
                                name: data.name, 
                                email: data.email,
                                mobile_number:data.mobile_number,
                                gender:data.gender,
                                address:data.address,
                                city:data.city,
                                state:data.state,
                                country:data.country
                            }}
                            enableReinitialize= {true}
                            validate={values => {
                                const errors = {};
                                
                                if (!values.name) {
                                    errors.name = 'Name is required.';
                                }
                                if (!values.email) {
                                    errors.email = 'Email is required.';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }
                                if (!values.mobile_number) {
                                    errors.mobile_number = 'Mobile number is required.';
                                }
                                if (!values.gender) {
                                    errors.gender = 'Gender is required.';
                                }
                                if (!values.address) {
                                    errors.address = 'Address is required.';
                                }
                                if (!values.city) {
                                    errors.city = 'City is required.';
                                }
                                if (!values.state) {
                                    errors.state = 'State is required.';
                                }
                                if (!values.country) {
                                    errors.country = 'Country is required.';
                                }
                                return errors;
                            }}
                            onSubmit={handleFormSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-6 mb-3'>
                                            <label>Name</label>
                                            <input 
                                             type="text" 
                                             className="form-control" 
                                             name="name"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.name}
                                             />
                                             {errors.name && touched.name && <span className='helpError'>{errors.name}</span>}
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>Email</label>
                                            <input 
                                              type="text" 
                                              className="form-control" 
                                              name="email"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.email}
                                              disabled
                                              />
                                               {errors.email && touched.email && <span className='helpError'>{errors.email}</span>}
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>Mobile Number</label>
                                            <input 
                                             type="text" 
                                             className="form-control" 
                                             name="mobile_number"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.mobile_number}
                                             />
                                             {errors.mobile_number && touched.mobile_number && <span className='helpError'>{errors.mobile_number}</span>}
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>Gender</label>
                                            <select className="form-control" name="gender" defaultValue={values.gender} onChange={handleChange}>
                                                <option value={'Male'}>Male</option>
                                                <option value={'Female'}>Female</option>
                                                <option value={'Others'}>Others</option>
                                            </select>
                                            {errors.gender && touched.gender && <span className='helpError'>{errors.gender}</span>}
                                        </div>
                                        <div className='col-md-12 mb-3'>
                                            <label>Address</label>
                                            <textarea 
                                            className='form-control'
                                            name="address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultValue={values.address}
                                            ></textarea>
                                            {errors.address && touched.address && <span className='helpError'>{errors.address}</span>}
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>City</label>
                                            <input 
                                             type="text" 
                                             className="form-control" 
                                             name="city"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.city}
                                             />
                                             {errors.city && touched.city && <span className='helpError'>{errors.city}</span>}
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>State</label>
                                            <input 
                                             type="text" 
                                             className="form-control" 
                                             name="state"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.state}
                                             />
                                             {errors.state && touched.state && <span className='helpError'>{errors.state}</span>}
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>Country</label>
                                            <input 
                                             type="text" 
                                             className="form-control" 
                                             name="country"
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.country}
                                             />
                                             {errors.country && touched.country && <span className='helpError'>{errors.country}</span>}
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <button type='submit' className='cPrimaryButton buttonFullWidth mt-4' disabled={isSubmitting}>Save</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default EditProfile