import React,{useState, useEffect, Component} from 'react'
import '../styles/login.css'
import { Link } from 'react-router-dom'
import { FaUserAlt } from 'react-icons/fa';
import { GetLogin } from '../helpers';
import { Alerts } from '../helpers';

export default class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        email: '',
        password: '',
        isLoading: false,
    }
}

handleEmailChange = (e) => {
  this.setState({
    email: e.target.value
  });
}

handlePasswordChange = (e) => {
  this.setState({
      password: e.target.value
  });
}

handleSubmit = async (e)=>{
  e.preventDefault();
  this.setState({isLoading:true})
  const formValues = {
    email:this.state.email,
    password:this.state.password
  }
  let resp = await GetLogin.getUserLogin(formValues);
  if(resp){
   this.setState({isLoading:false})
    if(resp.status){
      const roles = resp.userData.roles;
      let isSuperAdmin = roles.find(o => o === "ROLE_SUPER_ADMIN");
      if(isSuperAdmin !== undefined){
         this.persistUserDetails(resp);
       }else{
        Alerts.alertMessage('Authorization failed!', 'top-center', 'error')
      }
        
    }else{
      Alerts.alertMessage(resp.message, 'top-center', 'error')
    }
  }
}


persistUserDetails = (data) => {
  sessionStorage.setItem('isAuthenticated', 'true');
  sessionStorage.setItem('userData', JSON.stringify(data.userData));
  sessionStorage.setItem('userToken', data.accessToken);
  this.isLoggedIn();
};
isLoggedIn = () => {
  let isAuthenticated = sessionStorage.getItem('isAuthenticated');
  if (isAuthenticated && isAuthenticated === 'true') {
      let from = '/';
      if (this.props.location.state) {
          from = this.props.location.state.pre_path;
      }
      window.location.href = from; 
  }
};
  render() {
    const isAuthenticated = sessionStorage.getItem('isAuthenticated');
        if (isAuthenticated && isAuthenticated === 'true') {
          this.props.history.push('/');
        }
    const {email, password, isLoading} = this.state;
    return (
      <div className='authWrapper'>
      <div className='authForm'>
        <div className='authFormHeader'>
          <div className='authFormHeaderIcon'>
            <FaUserAlt />
          </div>

          <h1>Sign In</h1>
        </div>
        <div className='authFormBody'>
          <form onSubmit={this.handleSubmit}>
            <div className='mb-3'>
              <input 
               type="text" 
               className="form-control" 
               placeholder="Enter your username" 
               name="email" 
               value={email} 
               onChange={this.handleEmailChange}
               />
            </div>
            <div className='mb-3'>
              <input 
               type="password" 
               className="form-control" 
               placeholder="Password" 
               name="password" 
               value={password} 
               onChange={this.handlePasswordChange}/>
            </div>

            <button type="submit" className="t_primary_btn t_full_width " disabled={isLoading}>{isLoading? 'Please wait..':'Sign in'}</button>

          </form>
          <div className='forget_link'>
            <Link to="/">Forget Password</Link>
          </div>
        </div>
      </div>
    </div>
    )
  }
}
