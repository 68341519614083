import React from 'react'
import { Redirect, Route } from 'react-router-dom'
const Protected = ({ component: Cmp, ...rest }) => (

    <Route
        {...rest}
        render={(props) => {
            let isAuthenticated = sessionStorage.getItem('isAuthenticated');
            if (isAuthenticated && isAuthenticated === 'true') {
                var userdata = JSON.parse(sessionStorage.getItem('userData'))
                let isadmin = userdata.roles.find(o => o === "ROLE_SUPER_ADMIN");
                if (isadmin !== undefined) {
                    return <Cmp {...props} />
                } else {
                    sessionStorage.removeItem('isAuthenticated');
                    sessionStorage.removeItem('userData');
                    sessionStorage.removeItem('userToken');
                    return <Redirect to="/login" />
                }
            } else {
                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { pre_path: window.location.pathname }
                    }}
                />
            }
        }}
    />
)
export default Protected; 