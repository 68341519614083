import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';

 class Logout extends Component {
    handleLogout =(e) => {
        e.preventDefault();
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('userToken');
        return this.props.history.push('/login');
    }
    render() {
   
        return (
            <>
                <button className="profile-link" onClick={this.handleLogout}><FaSignOutAlt/> Logout</button>
            </>
        )
    }
}

export default withRouter(Logout);