import React from 'react'
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { FiUser } from 'react-icons/fi'
const ProfileDetails = ({ data, onEdit }) => {
    return (
        <div className='profileWrapper'>
            <Card>
            <Card.Header><h5><FiUser/> Profile</h5></Card.Header>
                <Card.Body>
                    <Table className='customTable'>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td>{data.name}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{data.email}</td>
                            </tr>
                            <tr>
                                <th>Mobile Number</th>
                                <td>{data.mobile_number}</td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td>{data.gender}</td>
                            </tr>
                            
                            <tr>
                                <th>Address</th>
                                <td>{data.address}</td>
                            </tr>
                            <tr>
                                <th>City</th>
                                <td>{data.city}</td>
                            </tr>
                            <tr>
                                <th>State</th>
                                <td>{data.state}</td>
                            </tr>
                            <tr>
                                <th>Country</th>
                                <td>{data.country}</td>
                            </tr>
                            <tr>
                                <th>Roles</th>
                                <td>{data.roles.map((o, i)=>{
                                    return <span key={i}>{o}</span>
                                })}</td>
                            </tr>
                            
                        </tbody>
                    </Table>
                    <button className='cPrimaryButton' onClick={onEdit}>Edit</button>
                </Card.Body>
            </Card>
            </div>
    )
}

export default ProfileDetails