import React from 'react'
import Breadcrumb from '../../components/partials/Breadcrumb'
import { Container, Row, Col, Card, Table } from 'react-bootstrap';

import { FaUsers, FaChartBar, FaShoppingBag, FaDollarSign } from 'react-icons/fa';
const breadcrumbs = [
  {
    id: 1,
    label: 'Dashboard',
    link: '/'
  }
]
const Dashboard = () => {

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className='innerPageWrapper'>
        <Container fluid="md">
          <div className='tiles'>
            <Row>
              <Col xs={4} md={3}>
                <Card className='tiles-item tiles-success'>
                  <Card.Body>
                    <div className='d-flex justify-content-between'>
                      <div className='mt-2'>
                        <h6>Total Users</h6>
                        <h2>44,278</h2>
                      </div>
                      <div className='tilesIcon'>
                        <FaUsers />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4} md={3}>
                <Card className='tiles-item tiles-success'>
                  <Card.Body>
                    <div className='d-flex justify-content-between'>
                      <div className='mt-2'>
                        <h6>Total Orders</h6>
                        <h2>50,278</h2>
                      </div>
                      <div className='tilesIcon'>
                        <FaChartBar />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4} md={3}>
                <Card className='tiles-item tiles-success'>
                  <Card.Body>
                    <div className='d-flex justify-content-between'>
                      <div className='mt-2'>
                        <h6>Total Sales</h6>
                        <h2>100,278</h2>
                      </div>
                      <div className='tilesIcon'>
                        <FaShoppingBag />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4} md={3}>
                <Card className='tiles-item tiles-success'>
                  <Card.Body>
                    <div className='d-flex justify-content-between'>
                      <div className='mt-2'>
                        <h6>Total Cost</h6>
                        <h2>44,278</h2>
                      </div>
                      <div className='tilesIcon'>
                        <FaDollarSign />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className='latest-orders'>
            <h2><FaShoppingBag /> Recent Orders</h2>
            <Table striped className='custom_table'>
              <thead>
                <tr>
                  <th>#SL</th>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Date/Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Trending Half Hands Tshirt</td>
                  <td>1</td>
                  <td>$700</td>
                  <td>Jul 28 2022</td>
                </tr>
                
              </tbody>
            </Table>
          </div>

        </Container>
      </div>
    </>
  )
}

export default Dashboard