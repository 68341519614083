import React, { Component } from 'react'
import ProfileDetails from './ProfileDetails'
import EditProfile from './EditProfile'
import { GetLogin, Alerts } from '../../../../helpers'
import Breadcrumb from '../../../../components/partials/Breadcrumb'

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isEdit: false,
      profile: {}
    }
  }

  onEdit = () => {
    this.setState({
      isEdit: true
    });
  }
  goBack = (e) => {
    this.setState({
      isEdit: false,
    });
  }

  onEditSubmit = async (data) => {
    let resp = await GetLogin.updateUserProfile(data);
    if (resp) {
      console.log(resp);
      this.getUser();
      this.setState({
        isEdit: false
      })

     Alerts.alertMessage(resp.message, "top-right", "success");
    }

  }
  getUser = async () => {
    let resp = await GetLogin.getUserDetails();
    if (resp) {
      this.setState({
        profile: resp,
        isLoading: false,
      })
    }
  }
  componentDidMount() {
    this.getUser();
  }
  render() {
    const { isEdit, isLoading, profile } = this.state;
    const breadcrumbs = [
      {
        id: 1,
        label: 'Dashboard',
        link: '/'
      },
      {
        id: 2,
        label: 'Profile',
        link: '/'
      }
    ]
    return (
      <>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <div className='innerPageWrapper'>
          {
            isLoading ? 'Data Loading...' :
              !isEdit ? <ProfileDetails data={profile} onEdit={this.onEdit} /> : <EditProfile goBack={this.goBack} data={profile} onEditSubmit={this.onEditSubmit} />
          }
        </div>
      </>
    )
  }
}
