import api from '../../ApiConfig';
import { Apis } from '../../config';

const getUserLogin = async (data) => {
  try {
    let result = await api.post(Apis.UserLogin, data);
    if (result.data.error) {
      return null;
    }
    return result.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
};
const getUserDetails = async () => {
  try {
    var token = sessionStorage.getItem('userToken');
    let result = await api.get(Apis.GetUserProfile, {
      headers: {
        'x-access-token': token
      }
    });
    if (result.data.error) {
      return null;
    }
    return result.data;

  } catch (error) {
    return null;
  }
};
const updateUserProfile = async (data) => {
  try {
    var token = sessionStorage.getItem('userToken');
    let result = await api.post(Apis.UpdateUserProfile, data, {
      headers: {
        'x-access-token': token
      }
    });
    if (result.data.error) {
      return null;
    }
    return result.data;

  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
}

const checkCurrentPassword = async (data) => {
  try {
    var token = sessionStorage.getItem('userToken');
    let result = await api.post(Apis.CheckUserCurrentPassword, data, {
      headers: {
        'x-access-token': token
      }
    });
    if (result.data.error) {
      return null;
    }
    return result.data;

  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
}

const updatePassword = async (data) => {
  try {
    var token = sessionStorage.getItem('userToken');
    let result = await api.put(Apis.UpdateUserPassword, data, {
      headers: {
        'x-access-token': token
      }
    });
    if (result.data.error) {
      return null;
    }
    return result.data;

  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return null;
  }
}


const exportedObject = {
  getUserLogin,
  getUserDetails,
  updateUserProfile,
  checkCurrentPassword,
  updatePassword
};
export default exportedObject;