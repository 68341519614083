import React from 'react'
import FontAwesome from 'react-fontawesome'
const Icon = ({icon, customClass}) => {
  return (
    <FontAwesome
      className={customClass}
      name={icon}
    />
  )
}

export default Icon